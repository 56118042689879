<template>
    <el-submenu :index="menuObj.marking">
        <div slot="title" :style="`padding-left:${layer}px;`">
            <i :class="`iconfont ${menuObj.icon}`"></i>
            <span>{{ menuObj.name }}</span>
        </div>
        <template v-for="(item, index) in menuObj.children">
            <template v-if="item.children && item.children.length > 0">
                <VerMenuEleItem :menuObj="item" :key="index" v-if="item.status === 1" :layer="layer + 10">
                </VerMenuEleItem>
            </template>
            <template v-else>
                <el-menu-item :index="item.marking" v-if="item.status === 1" :key="item.marking"
                    @click.native="jump(item)" :style="`padding-left:${layer + 10}px;`">
                    <i :class="`iconfont ${item.icon}`"></i>
                    <span slot="title">{{ item.name }}</span>
                </el-menu-item>
            </template>
        </template>
    </el-submenu>

</template>
<script>
export default {
    name: "VerMenuEleItem",
    components: {
        VerMenuEleItem: () => import("./VerMenuEleItem.vue"),
    },
    props: {
        layer: {
            type: Number,
            default: 20
        },
        menuObj: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            // paddingLeft:`padding-left:${this.layer*10}px;`,
        }
    },
    watch: {
        layer(a, b) {
            // this.paddingLeft = `padding-left:${a*10}px;`
        }
    },
    created() {
    },
    methods: {
        jump(item) {
            var path = `/${item.marking}`
            this.$router.push(path);
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/config.scss";

* {
    &:hover {
        background: none !important;
    }
}
</style>
